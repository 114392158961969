/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://unpkg.com/tailwindcss/dist/base.css
 */

@tailwind base;

/**
 * Remove the default box-shadow for invalid elements to prevent
 * inputs in Livewire components showing with a
 * red border by default in Firefox.
 *
 * See: https://github.com/laravel-frontend-presets/tall/issues/7
 */

input:invalid,
textarea:invalid,
select:invalid {
    box-shadow: none;
}


/**
 * This injects any component classes registered by plugins.
 */

@tailwind components;

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 */


/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 */

@tailwind utilities;
@import "imports";
@import "fonts";
@import "colors";
@import "variables";
@import "global";

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 */

[x-cloak] {
    display: none;
}