html {
    @apply subpixel-antialiased;
}

body {
    @apply antialiased text-black-500 text-sm max-w-full min-h-screen;
    font-family: "Noto Sans", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.53846;
}

main {
    // max-h-screen;
}
.time {
    font-weight: 400;
    text-align: right;
}

.day {
    font-weight: 600;
    text-align: left;
}

.app {
    @apply min-h-screen text-black-500;
    background-color: #fefcfc;
}

.content {
    @apply h-auto px-2 py-2 mt-2 mb-4;
}

.card {
    @apply p-8 mt-6 rounded-lg shadow-lg bg-white h-auto;
}

.box {
    @apply p-4 mt-3 rounded-md shadow-lg bg-white h-auto;
}

.intro {
    .intro-title {
        @apply text-center mb-2 text-3xl leading-8;
    }
    .intro-subtitle {
        @apply mb-2 text-lg font-light;
    }
}
