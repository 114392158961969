@layer base {
    @font-face {
        font-family: 'Noto Sans', sans-serif;
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Noto Sans', sans-serif;
        font-weight: 500;
        font-style: semibold;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: inherit;
        font-family: 'Noto Sans', sans-serif;
        font-weight: 700;
    }
    blockquote,
    hr,
    figure,
    p,
    a,
    pre {
        font-size: inherit;
        font-family: 'Noto Sans', sans-serif;
        font-weight: 400;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
